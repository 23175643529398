import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/empty.png'


const _withScopeId = n => (_pushScopeId("data-v-574d59bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Batch = _resolveComponent("Batch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", null, [
      ($data.batches.length == 0)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.batches, (batch) => {
        return (_openBlock(), _createBlock(_component_Batch, {
          key: batch.request_timestamp,
          batch: batch
        }, null, 8, ["batch"]))
      }), 128))
    ]),
    (this.moreBatchesAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.loadMore && $options.loadMore(...args))),
          class: "btn btn-primary"
        }, "Load more"))
      : _createCommentVNode("", true)
  ], 64))
}