import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a1701e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "batch-info" }
const _hoisted_2 = { id: "meta" }
const _hoisted_3 = { id: "amount" }
const _hoisted_4 = { id: "dynamic" }
const _hoisted_5 = {
  key: 0,
  id: "spinner"
}

export function render(_ctx: any,_cache: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["batch", { 'processing': !_ctx.batch.committed || _ctx.batch.exporting }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.format(this.batch.request_timestamp)) + " - ", 1),
        _createElementVNode("b", null, _toDisplayString(this.creator), 1)
      ]),
      _createElementVNode("div", _hoisted_3, "Amount: " + _toDisplayString(this.batch.amount), 1),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.batch.committed || _ctx.batch.exporting)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : _createCommentVNode("", true),
        (this.batch.available_on_disk)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadBatch())),
              id: "dl-btn",
              class: "btn"
            }, "Download CSV"))
          : _createCommentVNode("", true),
        (!this.batch.available_on_disk && this.batch.committed && !this.batch.exporting)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.exportBatch())),
              id: "export-btn",
              class: "btn"
            }, "Export CSV"))
          : _createCommentVNode("", true)
      ]),
      (this.batch.committed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: "expander",
            class: _normalizeClass({ 'expanded': !this.expand })
          }, [
            _createElementVNode("a", {
              class: "btn-expand",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (this.expand = !this.expand))
            })
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["details", { 'expanded': this.expand }])
    }, _toDisplayString(this.batch.min_number) + " ... " + _toDisplayString(this.batch.max_number), 3)
  ], 2))
}