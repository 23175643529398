
export default {
	name: 'Batch',
	components: {
	},
	props: ['batch'],
	data() {
		return {
			expand: false,
			creator: undefined,
		}
	},
	mounted() {
		this.$api.getBatchCreator(this.batch.id)
		.then((response: any) => {
			this.creator = response.data.display_name
			console.log(response)
		}).catch((error: any) => {
			console.log(error.response)
		}).finally(() => {
		})
	},
	methods: {
		format(timestamp: string) {
			let date = new Date(timestamp)
			// TODO localization
			let datestring = date.toLocaleDateString('de-DE', {
									   year: 'numeric',
									   month: '2-digit',
									   day: '2-digit',
									  })
			let timestring = date.toLocaleTimeString('de-DE', {
									   hour: '2-digit',
									   minute: '2-digit',
									  })
			return datestring + ', ' + timestring
		},
		exportBatch() {
			this.$api.exportBatch(this.batch.id)
			.then((response: any) => {
			}).catch((error: any) => {
				console.log(error.response)
			}).finally(() => {
			})
		},
		downloadBatch() {
			this.$api.downloadBatch(this.batch.id)
			.then((response: any) => {
				const anchor = <HTMLAnchorElement>document.createElement('a')
				anchor.href = `${process.env.VUE_APP_API_BASE_URL}${response.data.dl_path}`
				anchor.target = '_blank'
				anchor.click()
			}).catch((error: any) => {
				console.log(error.response)
			}).finally(() => {
			})
		}
	},
}
