
export default {
	name: 'ConfirmationPopup',
	props: ['country', 'amount'],
	data: () => {
		return {
			visible: false,
			resolvePromise: undefined,
        		rejectPromise: undefined,
		}
	},
	methods: {
		open() {
			this.visible = true
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve
				this.rejectPromise = reject
				})
		},
		_cancel() {
			this.visible = false
			this.resolvePromise(false)
		},
		_confirm() {
			this.visible = false
			this.resolvePromise(true)
		},
	}
}
