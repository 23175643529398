import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "countries" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Country = _resolveComponent("Country")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.countries, (country) => {
        return (_openBlock(), _createElementBlock("a", {
          onClick: ($event: any) => ($options.onChangeCountry(country.iso))
        }, [
          _createVNode(_component_Country, {
            countryIso: country.iso,
            selected: $data.selectedCountry == country.iso
          }, null, 8, ["countryIso", "selected"])
        ], 8, _hoisted_2))
      }), 256))
    ]),
    (this.selectedCountry != undefined)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.confirm && $options.confirm(...args))),
          class: "btn btn-primary"
        }, "Confirm"))
      : _createCommentVNode("", true)
  ], 64))
}