
export default {
	name: 'Country',
	props: ['countryIso', 'selected'],
	methods: {
		getFlagUrl() {
			return `${process.env.VUE_APP_API_BASE_URL}/asset/${this.countryIso.toLowerCase()}.png`
		},
	}
}
