
import Batch from '@/components/Batch.vue';

export default {
	name: 'BatchList',
	components: {
		Batch,
	},
	props: ['country'],
	data() {
		return {
			timer: "",
			batches: [],
			batchesShown: 5,
		}
	},
	computed: {
		offset: function() {
			return 0
		},
		moreBatchesAvailable: function() {
			return !(this.batches.length < this.batchesShown)
		}
	},
	mounted() {
		this.loadBatches()
		this.timer = setInterval(this.loadBatches, 1000)
	},
	unmounted() {
		this.cancelAutoUpdate()
	},
	methods: {
		format(timestamp: string) {
			let date = new Date(timestamp)
			// TODO localization
			return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
		},
		cancelAutoUpdate() {
			clearInterval(this.timer);
		},
		loadMore() {
			this.batchesShown = this.batchesShown + 5
		},
		loadBatches() {
			this.$api.getBatches(this.country, this.offset, this.batchesShown)
			.then((response: any) => {
				this.batches = response.data
			}).catch((error: any) => {
				console.log(error.response)
			}).finally(() => {
			})
		},
	},
}
