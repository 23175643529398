
import Country from '@/components/Country.vue'

export default {
	name: 'CountrySelect',
	components: {
		Country,
	},
	mounted() {
		this.loadCountries()
		this.selectedCountry = this.$store.state.country
		this.unsubscribeStore = this.$store.subscribe((mutation: any, state: any) => {
			if (mutation.type == 'setCountry' || mutation.type == 'unsetCountry') {
				this.selectedCountry = state.country
			}
		})
	},
	unmounted() {
		this.unsubscribeStore()
	},
	data() {
		return {
			countries: [],
			selectedCountry: undefined,
			unsubscribeStore: undefined,
		}
	},
	methods: {
		loadCountries() {
			this.$api.getCountries()
			.then((response : any) => {
				this.countries = response.data
			}).catch((error : any) => {
				console.log(error.response)
			}).finally(() => {})
		},
		onChangeCountry(iso: string) {
			this.selectedCountry = iso
			this.$store.dispatch('setCountry', { api: this.$api, country: iso })
		},
		confirm() {
			if (this.$store.state.country != '') {
				this.$router.push('/batch-manager')
			}
		}
	}
}
