import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "batch-manager" }
const _hoisted_2 = {
  key: 0,
  id: "countries"
}
const _hoisted_3 = { class: "form-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Country = _resolveComponent("Country")!
  const _component_ConfirmationPopup = _resolveComponent("ConfirmationPopup")!
  const _component_Batches = _resolveComponent("Batches")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.country != undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Country, {
            countryIso: this.country,
            selected: true
          }, null, 8, ["countryIso"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      class: "request-form",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestBatch && _ctx.requestBatch(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass({ 'invalid-input': this.invalidInputIndicate }),
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.amount) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (this.invalidInput=false)),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (this.invalidInputIndicate=false)),
          placeholder: "Amount"
        }, null, 34), [
          [_vModelText, _ctx.form.amount]
        ])
      ]),
      _createElementVNode("button", {
        class: _normalizeClass(["btn btn-request", { 'shake': this.shake }]),
        onAnimationend: _cache[3] || (_cache[3] = ($event: any) => (this.shake=false))
      }, "Generate new batch", 34),
      _createVNode(_component_ConfirmationPopup, {
        ref: "confirmationPopup",
        country: this.country,
        amount: this.form.amount
      }, null, 8, ["country", "amount"])
    ], 32),
    (this.country != undefined)
      ? (_openBlock(), _createBlock(_component_Batches, {
          key: 1,
          country: this.country
        }, null, 8, ["country"]))
      : _createCommentVNode("", true)
  ]))
}