
import { defineComponent } from 'vue';
import Batches from '@/components/Batches.vue';
import Country from '@/components/Country.vue'
import ConfirmationPopup from '@/components/ConfirmationPopup.vue'

export default defineComponent({
	name: 'BatchManager',
	components: {
		Batches,
		ConfirmationPopup,
		Country,
	},
	data() {
		return {
			country: undefined,
			form: {
				amount: '',
			},
			invalidInput: false,
			invalidInputIndicate: false,
			shake: false,
		}
	},
	mounted() {
  		if (this.$store.state.country == '') {
			this.$router.push('/country-select')
		} else {
			this.country = this.$store.state.country
		}

	},
	methods: {
		async requestBatch() {
			if (this.form.amount == '' || this.form.amount <= 0 || this.form.amount > 1000000000 || isNaN(this.form.amount) || this.invalidInput) {
				this.invalidInput = true
				this.invalidInputIndicate = true
				this.shake = true
				return
			}
			const confirmed = await this.$refs.confirmationPopup.open()
			if (!confirmed) {
				this.shake = true
				return
			}
			this.$api.allocateCards(this.country, this.form.amount)
			.then((response: any) => {
				if (response == 'Error: Request failed with status code 507') {
					this.invalidInput = true
					this.invalidInputIndicate = true
					this.shake = true
					alert('Requested amount of cards exceeds the remaining pool!')
				}
			})
			.catch((error: any) => {
				console.log(error.response)
			}).finally(() => {
			})
		},
	},
});
